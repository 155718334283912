import { Box, Typography } from '@mui/material'
import React from 'react'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import classes from './Footer.module.scss'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div
      style={{
        backgroundColor: '#0B1C48',
      }}
      className={classes.bg}
    >
      <Box
        sx={{
          mt: '90px',
          p: '25px',
          color: '#ffffff',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '1536px',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        <div className={classes.logoContainer}>
          <img
            src={require('../../Assets/Images/Eagle_Eye.png')}
            alt="Eagle Eye Worldwide"
            className={classes.logo}
            onClick={() => {
              navigate('/')
            }}
            style={{
              cursor: 'pointer',
              background: 'white',
              objectFit: 'contain',
              padding: '4px 0',
            }}
          />
          {/* <img
            src={require('../../Assets/Images/BLACKFIRE LOGO.jpg')}
            alt="Black Fire "
            className={classes.logo}
            style={{
              marginTop: '20px',
            }}
          /> */}
        </div>
        <div className={classes.logoContainer}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#e47e52',
              mb: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/about')
            }}
          >
            About Us
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              pb: '8px',
              width: '270px',
            }}
          >
            <Typography>
              At Eagle Eye Worldwide, we're committed to providing exceptional
              products and services to our clients. We specialize in a wide
              range of industries, including smart home technology, interior
              design, electrical engineering, aviation obstruction lights,
              traffic lights, GPS tracking devices, swimming pool construction,
              and more.{' '}
              <span
                style={{ color: '#e47e52', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/about')
                }}
              >
                read more
              </span>
            </Typography>
          </Box>
        </div>
        <div className={classes.logoContainer}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#e47e52',
              mb: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/contact')
            }}
          >
            Contact Us
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
            <PhoneIphoneIcon sx={{ mr: '4px' }} />
            <Typography>
              <a
                href="tel:+973 36118851"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                {' '}
                +973 36118851
              </a>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
            <PhoneIphoneIcon sx={{ mr: '4px' }} />
            <Typography>
              <a
                href="tel:+973 36118851"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                {' '}
                +91 9110836116
              </a>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pb: '8px' }}>
            <EmailIcon sx={{ mr: '4px' }} />
            <Typography>
              <a
                href="mailto:eagleeyeinfoworld@gmail.com"
                style={{ color: '#ffffff', textDecoration: 'none' }}
              >
                {' '}
                eagleeyeinfoworld@gmail.com
              </a>
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <LocationOnIcon sx={{ mr: '4px' }} />
            <Typography>
              Flat/shop no.0, bldg 873, <br />
              Road 527, Block 905, <br />
              Riffa/Alshargi
            </Typography>
          </Box>
        </div>
      </Box>
      <Typography
        sx={{
          color: '#ffffff',
          pb: '10px',
          textAlign: 'center',
          maxWidth: '1536px',
          ml: 'auto',
          mr: 'auto',
        }}
      >
        Copyright © 2023 Eagle Eye Worldwide. All Rights Reserved.
      </Typography>
    </div>
  )
}

export default Footer

import React from "react";
import classes from "../Services.module.scss";

const FireNSafety = () => {
  return (
    <div className={classes.serviceContainer}>
      <div className={classes.serviceHeader}>Safety & Product Solution</div>
      <div className={classes.serviceBody}>
        <div className={classes.serviceText}>
          <img
            src={require("../../../Assets/Images/Services/Services/firew.jpeg")}
            alt="Safety & Product Solution"
            className={classes.mainImg}
          />
          At Eagle Eye Worldwide, we understand the importance of safety in all
          aspects of life, especially in the workplace and public areas. That's
          why we offer a wide range of fire and safety equipment designed to
          protect people and property from harm. Our fire and safety equipment
          is sourced from trusted manufacturers and is compliant with industry
          standards.
          <br />
          <br />
          <h4>Fire Extinguishers</h4>
          We supply and install various types of fire extinguishers suitable for
          all types of fires. Our team of experts will assess your premises and
          recommend the most suitable fire extinguisher for your needs.
          <br />
          <br />
          <h4>Fire Alarm Systems</h4>
          We provide comprehensive fire alarm systems that detect fire at an
          early stage, allowing people to evacuate safely. Our fire alarm
          systems are designed to meet all your fire safety needs and are
          installed by certified technicians.
          <br />
          <br />
          <h4>Fire Suppression Systems</h4>
          We offer fire suppression systems that quickly detect and extinguish
          fires before they spread. These systems are ideal for large industrial
          settings, data centers, and other high-risk areas.
          <br />
          <br />
          <h4>Personal Protective Equipment (PPE)</h4>
          We provide a range of PPE such as fire-resistant clothing, helmets,
          and gloves to protect people working in high-risk environments.
          <img
            src={require("../../../Assets/Images/Services/Services/fire2.jpeg")}
            alt="Sewage Treatment"
            className={classes.mainImg}
          />
          <br />
          <br />
          <h4>First Aid Kits</h4>
          We supply and install first aid kits, ensuring that they are easily
          accessible in case of an emergency. Our first aid kits contain
          essential items for treating minor injuries.
          <br />
          <br />
          <h4>Safety Signage</h4>
          We offer a range of safety signage to communicate important safety
          messages and prevent accidents in the workplace.
          <br />
          <br />
          At Eagle Eye Worldwide, we pride ourselves on delivering high-quality
          fire and safety equipment services that meet our clients' needs.
          Contact us today to discuss your requirements and let us help you make
          your workplace a safer place.
        </div>
      </div>
    </div>
  );
};

export default FireNSafety;
